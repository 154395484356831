<template>
  <div class="row">
    <div class="col-md-6">
      <div class="card card-light" data-aos="fade-up" data-aos-duration="1800">
        <div class="card-body">
          <h1 class="first-gradient fs-38">Free</h1>
          <h3 class="fs-38">
            $0<small class="fs-21 aonik-light">/month</small>
          </h3>
          <h6>
            Limited Access to all features
          </h6>
          <ul>
            <li>Upload memories</li>
            <li>Annotate media </li>
            <li>Create family events</li>
            <li>Plan family events</li>
            <li>Build out your family tree</li>
            <li>Create timelines of your memories</li>
          </ul>
        </div>
        <div class="card-footer text-center">
          <a href="https://get.kinfolk.world/family" 
            class="btn signature-btn transparent btn-block w-100"
            target="_blank"
            rel="noopener noreferrer"
            >Try us out</a>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card" data-aos="fade-up" data-aos-duration="3000">
        <div class="card-body">
          <h3 class="white-gradient fs-38">Premium</h3>
          <h3 class="fs-38">
            $10<small class="fs-21 aonik-light">/month</small>
          </h3>
          <h6>
            Unlimited Access to all features
          </h6>
          <ul>
            <li>Upload memories</li>
            <li>Annotate media </li>
            <li>Create family events</li>
            <li>Plan family events</li>
            <li>Build out your family tree</li>
            <li>Create timelines of your memories</li>
          </ul>
        </div>
        <div class="card-footer text-center">
          <a href="https://get.kinfolk.world/family" 
            class="btn signature-btn btn-block w-100"
            target="_blank"
            rel="noopener noreferrer"
            >Get Started</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: 'Packages',
}
</script>

<style>
</style>