<template>
  <div class="container faq-section my-5 py-5">
    <div class="row h-100 align-items-top">
      <div class="col-md-12">
        <h3 class="fs-38 text-center py-4">Frequently Asked Questions</h3>
      </div>
      <div class="col-md-6" v-for="(item, index ) in items">
        <div class="accordion" id="accordionExample">
          <div class="accordion-item" >
            <h2 class="accordion-header">
              <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#collapse'+ index"
                  aria-expanded="true"
                  :aria-controls="'collapse' + index"
              >
                {{ item.heading }}
              </button>
            </h2>
            <div
                :id="'collapse' + index"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
            >
              <div class="accordion-body" v-html="item.desc"></div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Faq",
  data() {
    return {
      items: [
        {
          heading: "What is Kinfolk?",
          desc: "Kinfolk is a family connection app that allows you to track your family lineage, stay connected with family members, and preserve your family memories for generations to come. It's designed to bring families together and foster a greater sense of belonging and understanding among family members.",
        },
        {
          heading: "How do I get started with Kinfolk?",
          desc: "Getting started with Kinfolk is simple. Download the app from the App Store or Google Play Store, create an account, and start building your family tree. You can invite family members to join and collaborate on preserving your family memories together.",
        },
        {
          heading: "Is my family information secure on Kinfolk?",
          desc: "Absolutely! We take privacy and security seriously. Kinfolk includes robust privacy controls that allow you to choose who can view and contribute to your family tree. Your family information is stored securely, and we adhere to strict data protection guidelines to ensure your privacy is protected.",
        },
        {
          heading: "Can I use Kinfolk to research my family history?",
          desc: "While Kinfolk does not currently include research tools, you can use the storytelling feature to document and share your family's historical information. However, for in-depth genealogical research, we recommend utilizing specialized genealogy research tools and resources.",

        },
        {
          heading: "Is there a free trial available for Kinfolk?",
          desc: "Yes, we offer a freemium version that allows you to explore and experience the basic features of Kinfolk. You can then upgrade to the premium version whenever you are ready.",
        },
        {
          heading: "How can I contact support if I have questions or need assistance?",
          desc: "If you have any questions or need support, you can reach out to our dedicated support team through the Contact Us section of the app. We are here to help and ensure you have a seamless experience with Kinfolk.<br><br>Feel free to contact us if you have any other questions or need further assistance. We look forward to welcoming you to the Kinfolk community and helping you preserve the essence of your family culture for generations to come!",
        },

      ],
    };
  },
};
</script>

<style>
</style>
