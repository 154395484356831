<template>
  <!-- Hero Section -->
  <div class="container-fluid hero-section">
    <div class="row h-100 py-5 align-items-center justify-content-center text-center">
      <div class="col-md-7 pb-5 mb-5">
        <!-- Main heading with animation -->
        <h1 data-aos="fade-up" data-aos-duration="1800">
          Preserve the <span class="first-gradient">essence</span> <br />
          of your family <span class="second-gradient">culture</span>
        </h1>

        <!-- Call-to-action button with animation -->
        <a href="https://get.kinfolk.world/family"
          class="btn signature-btn mt-5"
          data-aos="fade-up"
          data-aos-duration="3000"
          target="_blank"
          rel="noopener noreferrer"
          >Get started</a>
      </div>
    </div>
  </div>

  <!-- About Section -->
  <section class="about-section">
    <div class="container py-5">
      <div class="row py-5 h-100 align-items-center">
        <!-- Left column with image -->
        <div class="col-md-6">
          <img
            data-aos="fade-up"
            data-aos-duration="3000"
            class="w-100"
            src="../assets/images/about.png"
            alt=""
          />
        </div>
        <!-- Right column with text content -->
        <div class="col-md-6">
          <h3 class="fs-38 mt-md-0 mt-4">
            Create a rich, interactive family history that captures your family's stories
            and traditions for generations to come!
          </h3>
          <p class="col-md-10">
            Kinfolk is a family connection app that goes beyond just building a family tree. With features for storytelling, event planning and much more. Kinfolk is designed to bring families closer together.
          </p>
          <a href="https://get.kinfolk.world/family" 
            class="btn cta w-auto"
            target="_blank"
            rel="noopener noreferrer"
            >Learn more</a>
        </div>
      </div>
    </div>
  </section>

  <!-- Features Section -->
  <div class="container features-section text-center">
    <div class="row justify-content-center">
      <div class="col-12">
        <h2 class="section-title">Kinfolk's amazing features</h2>
      </div>
    </div>
    <div class="row justify-content-center align-items-stretch g-4">
      <!-- Family Events feature -->
      <div class="col-md-4 col-lg-3" data-aos="fade-up" data-aos-duration="1000">
        <div class="card text-center bg-white h-100">
          <div class="card-header">
            <img src="../assets/images/home/Maskgroup(7).png" alt="Family Events" />
          </div>
          <div class="card-body">
            <h5>Family Events</h5>
            <p>Plan and organize family gatherings while creating a lasting record of the event.</p>
          </div>
        </div>
      </div>
      <!-- Family Relations feature -->
      <div class="col-md-4 col-lg-3" data-aos="fade-up" data-aos-duration="1500">
        <div class="card text-center bg-white h-100">
          <div class="card-header">
            <img src="../assets/images/home/Maskgroup(6).png" alt="Family Relations" />
          </div>
          <div class="card-body">
            <h5>Family Relations</h5>
            <p>Build out your family relationships when you invite new kin .</p>
          </div>
        </div>
      </div>
      <!-- Culture feature -->
      <div class="col-md-4 col-lg-3" data-aos="fade-up" data-aos-duration="2000">
        <div class="card text-center bg-white h-100">
          <div class="card-header">
            <img src="../assets/images/home/Maskgroup(5).png" alt="Culture" />
          </div>
          <div class="card-body">
            <h5>Culture</h5>
            <p>Preserve customs, values, stories that come together to create a family culture.</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Be a Part Section -->
  <div class="container be-apart-section p-md-5 p-4 my-5">
    <div class="row h-100 p-md-4 align-items-center">
      <!-- Left column with text content -->
      <div class="col-md-6">
        <h3 class="fs-34">Be part of something bigger:</h3>
        <ol>
          <li>
            By preserving family memories and traditions, kinfolk can help users reflect on their roots and identity, providing a sense of meaning and purpose. Reflecting on our personal history and values can give us a greater sense of fulfillment and direction in life.
          </li>
          <li></li>
          <li>
            Kinfolk allows users to leave a lasting legacy for future generations. Knowing that our stories and traditions will be passed down can give us a sense of fulfillment and purpose, knowing that we are contributing to something larger than ourselves.
          </li>
        </ol>
        <a href="#" class="btn cta w-auto">Learn more</a>
      </div>
      <!-- Right column with image -->
      <div class="col-md-6 mt-md-0 mt-5">
        <img
          class="w-100"
          data-aos="fade-up"
          data-aos-duration="2500"
          src="../assets/images/be-apart.png"
          alt="Be a part of something bigger"
        />
      </div>
    </div>
  </div>

  <!-- Spacer -->
  <div class="py-4"></div>

  <!-- Legacy Section -->
  <div class="container-fluid legacy-section p-x-5 px-4 mt-5">
    <div class="row h-100 align-items-center">
      <div class="col-md-12">
        <h1 class="text-center fs-58">With kinfolk you leave a lasting legacy</h1>
      </div>
    </div>
  </div>

  <!-- Pricing Section -->
  <div class="container-fluid pricing-section px-md-5 pblock100 mb-5">
    <div class="row h-100 py-5 align-items-center">
      <!-- Pricing heading -->
      <div
        class="col-md-6 mx-auto text-center text-white"
        data-aos="fade-up"
        data-aos-duration="1800"
      >
        <h3 class="fs-38">Simple pricing, for everyone</h3>
        <p class="fs-21 aonik-light">
          KinFolk charges a subscription plan of $10 per month for users who wish to tap into kinfolks potential
        </p>
      </div>
      <!-- Pricing packages component -->
      <div class="col-md-7 mx-auto text-center text-white">
        <packages />
      </div>
    </div>
  </div>

  <!-- FAQ Section -->
  <Faq />
</template>

<script>
import Packages from "./Packages.vue";
import Faq from "./Faq.vue";

export default {
  // Component name
  name: "Home",

  // Imported components
  components: { Packages, Faq },

  // Props (not used in this component)
  props: {
    msg: String,
  },

  // Component data
  data() {
    return {
      pageTitle: "Kinfolk",
    };
  },

  // Lifecycle hook: called after the component is mounted
  mounted() {
    // Set the document title
    document.title = this.pageTitle;
  },
};
</script>

<!-- Scoped styles for this component -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.features-section {
  padding: 80px 0;
}

.section-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 3rem;
}

.card {
  margin-bottom: 0;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.card-header {
  background: none;
  border: none;
  padding: 30px;
}

.card-header img {
  max-width: 120px;
  height: auto;
  margin: 10px 0;
}

.card-body {
  padding: 20px;
}

.card-body h5 {
  color: #1F93E7;
  margin-bottom: 15px;
  font-size: 1.25rem;
}

.card-body p {
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 0;
}

@media (max-width: 991.98px) {
  .features-section {
    padding: 60px 0;
  }
  
  .section-title {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
}
</style>
