<template>
  <Header />
  <router-view />
 <Footer/>
</template>
<script>
import Header from "../src/components/Header.vue";
import Footer from "../src/components/Footer.vue";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import '../src/assets/css/style.css'

import { NavbarPlugin } from 'bootstrap-vue'
export default {
  components: {
    Header,
    Footer
  },
  mounted() {
   AOS.init()
 },
};
</script>
<style>


/*#app {*/
/*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-align: center;*/
/*  color: #2c3e50;*/
/*}*/

/*nav {*/
/*  padding: 30px;*/
/*}*/

/*nav a {*/
/*  font-weight: bold;*/
/*  color: #2c3e50;*/
/*}*/

/*nav a.router-link-exact-active {*/
/*  color: #42b983;*/
/*}*/
</style>
